<template>
  <div class="cellList clearFloat" :style="{ height: (clientHeight - 75) + 'px' }">

    <div style="text-align: center; line-height: 50px; font-size: 20px;"><b>选择仓库</b></div>
    <el-card class="cangku clearFloat" style="margin: 20px;">
      <li class="leftFloat" v-for="(ware, index) in wares" :key="index">
        <span :title="ware.wareName" @click="getShelfData(ware.wareCode)"
          :class="now.wareCode === ware.wareCode ? 'hover' : ''">
          <span> {{ ware.wareCode }}-{{ ware.wareName }}</span>
        </span>
      </li>
    </el-card>
    <!-- <div class=" clearFloat" style="text-align: center; line-height: 50px; font-size: 20px;"><b>货架排列:</b></div> -->
    <div class=" clearFloat" style="display: flex;">
      <div style="flex: 1; text-align: center; line-height: 50px; font-size: 14px;" v-if="shelfs.length == 0">暂无信息</div>
      <div v-else style="flex: 1; margin: 20px;" v-for="(s, i) in shelfs" :key="i">
        <el-card>
          <div style="text-align: center; line-height: 50px; font-size: 14px;" v-if="s.length == 0">暂无信息</div>
          <div v-else style=" color: #fff; text-align: center; font-size: 14px; padding: 10px; margin: 10px;"
            v-for="(shelf, index) in s" @click="dialogCellVisible = true; getCellData(shelf)" :key="index"
            :title="shelf.shelfCode"
            :style="now.shelfInfoId === shelf.shelfInfoId  && dialogCellVisible? { background: 'chocolate' } :(shelf.status===1?{ backgroundColor: 'green' } : { backgroundColor: '#999999' })">{{
              shelf.shelfCode }}<br />{{ shelf.shelfName }}
          </div>
        </el-card>
        <div style="margin: 30px;" class="clearFloat">
          <div class="leftFloat ">
            <div class="leftFloat" style="width: 60px; height: 30px; margin-right: 10px; background-color: chocolate;">
            </div>
            <div class="leftFloat" style="line-height: 30px;">选中</div>
          </div>
          <div class="leftFloat ">
            <div class="leftFloat" style="width: 60px; height: 30px; margin-right: 10px; background-color: #999999;">
            </div>
            <div class="leftFloat" style="line-height: 30px;">无货</div>
          </div>
          <div class="leftFloat ">
            <div class="leftFloat"
              style="width: 60px; height: 30px; margin-left: 20px; margin-right: 10px; background-color: green;"></div>
            <div class="leftFloat" style="line-height: 30px;">有货</div>
          </div>
        </div>
      </div>
      
    </div>

    <el-dialog title="货架信息" :visible.sync="dialogCellVisible" width="80%">
      <div v-loading="cellLoading">
        <div class="clearFloat">
          <el-card>
            <h1 v-if="cells && cells.length != 0"
              style="text-align: center;margin-top: 10px; margin-bottom: 20px; color: #606266;">{{ shelfCode }}</h1>
            <div v-if="cells && cells.length != 0" v-loading="loading.calcW">
              <div style="display: flex; color: #fff;" v-for="(cellRows, rIndex) in cells" :key="rIndex">
                <div style="flex: 1; font-size: 14px;  margin: 5px; text-align: center; padding: 10px;"
                  v-for="(cell, index) in cellRows" :key="index"
                  :style="cell.state === 'disable' ? { backgroundColor: 'red' } :(dialogCellInfoVisible && cell.cellCode===now.cellCode?{ background: 'chocolate' }: (cell.status === 1 ? { backgroundColor: 'green' } : { backgroundColor: '#999999' }))"
                  @click="showCellModal(cell)" :title="cell.title">{{ cell.sRow }}-{{ cell.sColumn }}<br />{{ cell.cellName
                  }}
                </div>
              </div>
            </div>
            <p v-if="!cells || cells.length === 0" class="messageP">未查询到货位</p>
          </el-card>
        </div>

        <div style="margin: 30px;" class="clearFloat">
          <div class="leftFloat ">
            <div class="leftFloat" style="width: 60px; height: 30px; margin-right: 10px; background-color: chocolate;">
            </div>
            <div class="leftFloat" style="line-height: 30px;">选中</div>
          </div>
          <div class="leftFloat ">
            <div class="leftFloat" style="width: 60px; height: 30px; margin-right: 10px; background-color: #999999;">
            </div>
            <div class="leftFloat" style="line-height: 30px;">无货</div>
          </div>
          <div class="leftFloat ">
            <div class="leftFloat"
              style="width: 60px; height: 30px; margin-left: 20px; margin-right: 10px; background-color: green;"></div>
            <div class="leftFloat" style="line-height: 30px;">有货</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="货位信息" :visible.sync="dialogCellInfoVisible" width="80%" style="margin-top: -14vh;">
      <el-row>
        <el-col :span="12" v-for="(item, index) in lookCell" :key="index">
          <el-row>
            <el-col :span="10">
              <div style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    margin: 5px 0px 0px 25px;
                    font-weight:500;">
                <span v-if="item.label">{{ item.label }}:</span>
                <span v-else>-</span>
                <i></i>
              </div>
            </el-col>
            <el-col :span="14">
              <div style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                  margin: 5px 0px 0px 0px;
                  font-weight:700;">
                <span v-if="item.label === '状态'" style="background: #67C23A;color: #fff;padding: 0 5px;">启用</span>
                <span v-else-if="item.label === '状态'" style="background: #F56C6C;color: #fff;padding: 0 5px;">禁用</span>
                <span v-else-if="item.value">{{ item.value }}</span>
                <span v-else>-</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- {{inventorys}} -->
      <el-table v-loading="inventorysLoading" :data="inventorys" stripe style="width: 100%;margin-top:20px">
        <el-table-column prop="organizationName" label="工厂" min-width="120">
        </el-table-column>
        <el-table-column prop="cellCode" label="货位" min-width="140">
        </el-table-column>
        <el-table-column prop="itemCode" label="SKU" min-width="100">
        </el-table-column>        
        <el-table-column prop="itemName" label="物料名" min-width="180">
        </el-table-column>
        <el-table-column prop="model" label="型号规格" min-width="180">
          <template slot-scope="scope">
            {{ scope.row.model }}{{ scope.row.spec }}
          </template>
        </el-table-column>
        <el-table-column prop="batchName" label="批次" min-width="120">
        </el-table-column>
        <el-table-column prop="quantity" label="数量" min-width="100">
        </el-table-column>
        <el-table-column prop="packDescribe" min-width="80" label="单位">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCellInfoVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CellList',
  data() {
    return {
      organizationCode: PF.getLocal('organizationCode'),
      loading: {
        calcW: false,
      },
      areaLoading: false,
      shelfLoading: false,
      cellLoading: false,
      inventorysLoading: false,
      dialogCellInfoVisible: false,
      dialogCellVisible: false,
      wares: [],
      shelfs: [],
      cells: [],
      style: {},
      width: '',
      // row: 2,
      // clo:3,
      shelfCode: '',
      inventorys: [],
      lookCell: {},
      now: {
        areaId: null,
        shelfInfoId: null,
        wareCode:null,
        cellCode:null,
      },
      clientHeight: $(window).height(),
    }
  },
  watch: {
  },
  methods: {
    getAreaData() {
      this.areaLoading = true;
      IOT.getServerData('/ware/infos/findSome', 'get', { organizationCode: this.organizationCode }, (ret) => {
        this.areaLoading = false;
        if (ret.code === 200) {
          let list = ret.rows;
          this.wares = list;
          if (list.length == 0) {
            return
          }
          this.getShelfData(list[0].wareCode);
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getShelfData(wareCode) {
      let that = this;
      that.loading.calcW = true;
      that.now.wareCode = wareCode;
      that.shelfLoading = true;
      IOT.getServerData('/shelf/infos/list', 'get', { wareCode: wareCode }, (ret) => {
        that.shelfLoading = false;
        if (ret.code === 200) {
          let list = ret.rows;
          let maxColumn = 0;
          let arr = [];
          for (let i = 0; i < list.length; i++) {
            if (maxColumn < list[i].shelfColumn) {
              maxColumn = list[i].shelfColumn
            }
          }
          if (maxColumn > 0) {
            for (let i = 0; i < maxColumn; i++) {
              arr.push([]);
            }
          }
          for (let i = 0; i < list.length; i++) {
            arr[list[i].shelfColumn - 1].push(list[i])
          }
          that.shelfs = arr;
          if (list.length == 0) {
            that.cells = [];
            that.shelfCode = "";
            return
          }
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getCellData(shelf) {
      var that = this;
      that.cells = []
      that.shelfCode = shelf.shelfCode + "    " + shelf.shelfName;
      that.now.shelfInfoId = shelf.shelfInfoId;
      that.cellLoading = true;
      IOT.getServerData('/cell/infos/findByShelfInfoId', 'get', { wareId: that.wareId, shelfInfoId: shelf.shelfInfoId }, (ret) => {
        that.cellLoading = false;
        if (ret.code === 200) {
          let list = ret.data;
          let arr = [];
          for (let i = 0; i < list.length; i++) {
            list[i].title = `
                名   称 : ${list[i].cellName}
                编   码 : ${list[i].cellCode}
                状   态 : ${list[i].state} 
               `;
            if (arr[list[i].sRow - 1] == null) {
              arr[list[i].sRow - 1] = []
            }
            arr[list[i].sRow - 1].push(list[i]);
          }

          that.isDom(list, shelf.type);
          that.cells = arr;
          //console.log(arr);
          that.loading.calcW = false;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, true);
    },
    isDom(list, type) {
      var that = this;
      //let cells = document.querySelectorAll('.cellBox');

      that.calcWidth(list[list.length - 1].sRow, list[list.length - 1].sColumn, type);
      // if(cells.length === list.length){
      //   that.calcWidth(list[list.length -1].sRow, list[list.length -1].sColumn, type);
      // }else{
      //   setTimeout(()=>{
      //     that.isDom(list, type);
      //   },100);
      // }
    },
    calcWidth(r, c, type) {

      let that = this;
      let cells = document.querySelectorAll('.cellBox');
      let oneR = (100 / Number(r));
      var width = 'calc( ' + oneR + '% - 10px )';
      that.width = width;
      // for(let i=0;i<cells.length;i++){
      //   cells[i].style.width = 'calc( ' + oneR + '% - 10px )';
      //    
      // }
      // if(type === 1){
      //   for(let i=0;i<cells.length;i++){
      //     cells[i].style.width = 'calc( 20% - 20px )';
      //   }
      // }else if(type === 2){
      //   let oneR = (100 / Number(r));
      //   for(let i=0;i<cells.length;i++){
      //     cells[i].style.width = 'calc( ' + oneR + '% - 20px )';
      //   }
      // }
    },
    showCellModal(row) {
      let that = this;
      let list = [];
      for (let key in row) {
        if (key === 'cellName') { list.push({ label: '名称', value: row[key] }) }
        else if (key === 'cellCode') { list.push({ label: '编码', value: row[key] }) }
        // else if(key === 'shelfCode'){list.push({label: '所属货位组', value: row[key]})}
        else if (key === 'cellUseType') { list.push({ label: '货位使用类型', value: row[key] }) }
        // else if(key === 'cellAbcLevel'){list.push({label: '周转需求', value: row[key]})}
        // else if(key === 'cellPickType'){list.push({label: '拣货方式', value: row[key]})}
        else if (key === 'groundOrder') { list.push({ label: '上架顺序', value: row[key] }) }
        else if (key === 'pickOrder') { list.push({ label: '拣货顺序', value: row[key] }) }
        // else if(key === 'length'){list.push({label: '长', value: row[key]})}
        // else if(key === 'wide'){list.push({label: '宽', value: row[key]})}
        // else if(key === 'high'){list.push({label: '高', value: row[key]})}
        // else if(key === 'volumeLimit'){list.push({label: '体积限制', value: row[key]})}
        // else if(key === 'weightLimit'){list.push({label: '重量限制', value: row[key]})}
        // else if(key === 'boxLimit'){list.push({label: '箱数限制', value: row[key]})}
        // else if(key === 'palletLimit'){list.push({label: '托盘限制', value: row[key]})}
        else if (key === 'xCoordinate') { list.push({ label: 'X坐标', value: row[key] }) }
        else if (key === 'yCoordinate') { list.push({ label: 'Y坐标', value: row[key] }) }
        else if (key === 'state') { list.push({ label: '状态', value: row[key] }) }
      }

      that.lookCell = list;
      that.inventorys = [];
      that.now.cellCode = row.cellCode;
      that.inventorysLoading = true;
      IOT.getServerData('/inventorys/list', 'get', {
        wareId: row.wareId,
        cellCode: row.cellCode
      }, (ret) => {
        that.inventorysLoading = false;
        if (ret.code === 200) {
          that.inventorys = ret.rows;
          // that.searchData.total = ret.total;
          // that.searchData.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
      that.dialogCellInfoVisible = true;
    },

  },
  created() {
    this.getAreaData();
  },
  mounted() {
  }
}
</script>

<style scoped lang="less">
.cellList {
  color: #606266;

  >.cangku {
    font-size: 14px;

    li {
      line-height: 40px;

      >span {
        margin: 0 10px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        border: 1px solid #DCDFE6;
        background: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;
      }

      >span.hover {
        background: #3a8ee6;
        color: #fff;
      }

      >span:hover {
        background: #3a8ee6;
        color: #fff;
      }

      >span:active {
        background: #3a8ee6;
        color: #fff;
      }
    }

    >li:first-child {
      border-bottom: 1px solid #DCDFE6;
      margin-bottom: 10px;
    }
  }
}

>.huowei {
  padding: 0 20px;

  >.top {
    margin-bottom: 20px;

    .bigCellBox {
      .cellBox {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
        outline: 1px solid #EBEEF5;
        width: calc(20% - 10px);
        margin: 5px 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        cursor: pointer;
        border-radius: 4px;
      }

      .cellBox.info {
        /*background: #d3d3d3;*/
        background: #909399;
        color: #fff;
      }

      .cellBox.success {
        /*background: #90ee90;*/
        background: #67C23A;
        color: #fff;
      }

      .cellBox.danger {
        /*background: #f08080;*/
        background: #F56C6C;
        color: #fff;
      }
    }
  }

  >.bottom {
    border-top: 1px solid #DCDFE6;

    >.leftFloat {
      padding: 20px;

      >div {
        border-left: 8px solid #409eff;
        border-radius: 4px 0 0 4px;
        padding: 10px 20px;

        >p {
          line-height: 20px;

          >span {
            float: left;
            margin-right: 5px;
          }

          .colorBox {
            display: inline-block;
            width: 50px;
            height: 20px;
            border: 1px solid;
            border-radius: 4px;
          }

          .colorBox.info {
            /*border-color: #d3d3d3;*/
            /*background: #d3d3d3;*/
            border-color: #909399;
            background: #909399;
          }

          .colorBox.success {
            /*border-color: #90ee90;*/
            /*background: #90ee90;*/
            border-color: #67C23A;
            background: #67C23A;
          }

          .colorBox.danger {
            /*border-color: #f08080;*/
            /*background: #f08080;*/
            border-color: #F56C6C;
            background: #F56C6C;
          }
        }

        >p:first-child {
          margin-bottom: 10px;
        }

        >p:last-child {
          span:last-child {
            color: red;
          }
        }

        >p:after {
          display: block;
          clear: both;
          content: "";
          visibility: hidden;
          height: 0
        }

        >p {
          zoom: 1
        }
      }
    }
  }
}
</style>
